body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

#header-row {
  position: fixed;
  color: black;
  font-family: "Advent Pro", sans-serif;
  background-color: transparent;
}

@media screen and (min-width: 200px) {
  .mdl-layout__header {
    display: inline !important;
    display: initial !important;
  }
}

@media screen and (max-width: 525px) {
  .mdl-layout__header-row {
    padding: 0 !important;
  }
  .mdl-layout__header-row .mdl-navigation__link {
    padding: 0 5px !important;
  }
  #navigationText {
    font-size: 6.8vw !important;
  }
}

#navigationText {
  background-color: transparent;
  color: #c432a1;
  font-size: x-large;
  text-decoration: none;
}

nav {
  background-color: rgba(0, 0, 0, 0);
}

.video {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0;
  background-repeat: no-repeat;
  z-index: 1;
  display: block;
  position: relative;
  text-align: left;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
}

.sectionPage {
  font-family: "News Cycle", sans-serif;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
  margin: auto;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 4%;
  padding-bottom: 4%;
  display: block;
  position: relative;
  text-align: left;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
}

#myprojectdisplays {
  display: flex;
  align-items: center;
}

.bodyText {
  font-family: "News Cycle", sans-serif;
  font-weight: 300;
  font-size: 50px;
}

#CollapseSection {
  width: 70%;
}

html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(168, 213, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(79, 76, 90);
}

#home-page-background {
  font-family: "News Cycle", sans-serif;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
  margin: auto;
  z-index: 1;
  display: block;
  position: relative;
  text-align: left;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
}

#name-title {
  color: #00e0f0;
}

#loop-text {
  font-family: "News Cycle", sans-serif;
  color: #c432a1;
}

@media screen and (max-width: 925px) {
  #name-title {
    font-size: 20vw;
    top: 50%;
    left: 11%;
  }

  #loop-text {
    font-size: 8vw !important;
  }
}

@media screen and (min-width: 925px) {
  #name-title {
    font-size: 90px;
    top: 50%;
    left: 57%;
  }

  #loop-text {
    font-size: 40px !important;
  }
}

#Projects { /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #c432a1,
    #0e0849
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#projects-title {
  font-family: "Heebo", sans-serif;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    39deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(55, 37, 88, 1) 60%
  );
  -webkit-background-clip: text;
          background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;
  font-size: 70px;
}

@media screen and (max-width: 515px) {
  #projects-title {
    text-align: center;
    font-size: 14vw;
    padding-top: 8.5%;
    background: linear-gradient(
      39deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(55, 37, 88, 1) 100%
    );
    -webkit-background-clip: text;
            background-clip: text;
  }
}

#projects {
  color: aliceblue;
  position: relative;
  display: block;
  z-index: 1;
  margin-bottom: 5em;
  margin-top: 10vh;
}

.modal {
  width: 70%;
  height: auto;
}

#popUpModal {
  background: #321a5c; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #131a43, #321a5c);
  font-size: large;
  font-family: inherit;
  color: rgb(168, 213, 255);
  align-items: center;
  margin: 5;
  padding: 10;
  margin-bottom: 20;
}

h4 {
  font-family: unset !important;
  color: rgb(192, 223, 252);
}

.responsive-vid {
  /* animation-play-state: paused; */
  height: 100%;
  margin: auto;
  z-index: 1;
  /* display: block; */
  position: relative;
}

.make-grid {
  align-items: center;
}

.picApps {
  width: 10%;
  height: 20%;
}

#project-title-text {
  text-align: center;
  font-size: large;
  font-family: "Heebo", sans-serif;
}

.display-items-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media screen and (max-width: 600px) {
  .modal {
    width: 80%;
  }

  .responsive-vid {
    width: 100%;
  }

  img {
    width: 100%;
  }

  #img-row-2 {
    width: 50%;
  }

  .container {
    margin: 0%;
    width: 100%;
  }
}

@media screen and (min-width: 601px) {
  p {
    font-size: large !important;
  }

  .responsive-vid {
    width: 40%;
  }

  img {
    width: 35%;
  }

  #img-row-2 {
    width: 30%;
  }

  .carousel {
    overflow: visible;
    width: 300px;
  }

  .carousel .carousel-item {
    width: 300px;
    overflow: visible;
  }

  #ToDoModal,
  #WebsiteModal {
    width: 40%;
  }
}

#img-row-2 {
  padding: 1%;
}

#Designs {
  background-image: url(/static/media/DesignBackground2.709ffa13.jpg);
}

@media screen and (max-width: 925px) {
  #Designs {
    background-position-x: 15%;
  }
}

#aboutme {
  align-items: center;
  background: #1c92d2; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #131a43,
    #321a5c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#about-me-title {
  font-family: "Heebo", sans-serif;
  color: #c432a1;
  font-weight: 300;
  font-size: 70px;
}

.collapsible-header {
  color: #c432a1;
  background-color: transparent;
  font-size: larger;
}

.collapsible-body {
  font-size: larger;
}

#aboutMeSection {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  color: rgb(168, 213, 255);

  /* padding: 0 16px 16px; */
}

#AboutPicture {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25vw;
  /* width: 25%; */
  padding-right: 20px;
}

#aboutMeWritten {
  font-size: large;
  /* font-size: 1.8vw; */
  display: flex;
  text-align: left;
  align-items: center;
}

#connect {
  font-size: 25px;
  color: #2382ff;
  white-space: nowrap;
  float: right;
  padding-top: 5vh;
}

#AboutMe {
  font-family: "News Cycle", sans-serif;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: -webkit-fit-content;
  height: -moz-fit-content;
  height: fit-content;
  min-height: 100vh;
  margin: 0;
  z-index: 1;
  display: block;
  position: relative;
  text-align: left;
  min-width: 100%;
  overflow: hidden;
  align-items: center;
  background: #1c92d2; /* fallback for old browsers */ /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #131a43,
    #321a5c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@media screen and (max-width: 646px) {
  #about-me-title {
    text-align: right;
    font-size: 14vw;
    padding-top: 8.5%;
    line-height: 60%;
  }

  #connect {
    font-size: 5vw;
    float: unset;
  }

  #aboutMeSection {
    padding-left: 4%;
    padding-right: 4%;
  }

  #AboutPicture {
    display: none;
  }

  #aboutMeWritten {
    font-size: medium;
  }
}

