#projects {
  color: aliceblue;
  position: relative;
  display: block;
  z-index: 1;
  margin-bottom: 5em;
  margin-top: 10vh;
}

.modal {
  width: 70%;
  height: auto;
}

#popUpModal {
  background: #321a5c; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to bottom,
    #131a43,
    #321a5c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(to bottom, #131a43, #321a5c);
  font-size: large;
  font-family: inherit;
  color: rgb(168, 213, 255);
  align-items: center;
  margin: 5;
  padding: 10;
  margin-bottom: 20;
}

h4 {
  font-family: unset !important;
  color: rgb(192, 223, 252);
}

.responsive-vid {
  /* animation-play-state: paused; */
  height: 100%;
  margin: auto;
  z-index: 1;
  /* display: block; */
  position: relative;
}

.make-grid {
  align-items: center;
}

.picApps {
  width: 10%;
  height: 20%;
}

#project-title-text {
  text-align: center;
  font-size: large;
  font-family: "Heebo", sans-serif;
}

.display-items-center {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

@media screen and (max-width: 600px) {
  .modal {
    width: 80%;
  }

  .responsive-vid {
    width: 100%;
  }

  img {
    width: 100%;
  }

  #img-row-2 {
    width: 50%;
  }

  .container {
    margin: 0%;
    width: 100%;
  }
}

@media screen and (min-width: 601px) {
  p {
    font-size: large !important;
  }

  .responsive-vid {
    width: 40%;
  }

  img {
    width: 35%;
  }

  #img-row-2 {
    width: 30%;
  }

  .carousel {
    overflow: visible;
    width: 300px;
  }

  .carousel .carousel-item {
    width: 300px;
    overflow: visible;
  }

  #ToDoModal,
  #WebsiteModal {
    width: 40%;
  }
}

#img-row-2 {
  padding: 1%;
}
