#header-row {
  position: fixed;
  color: black;
  font-family: "Advent Pro", sans-serif;
  background-color: transparent;
}

@media screen and (min-width: 200px) {
  .mdl-layout__header {
    display: initial !important;
  }
}

@media screen and (max-width: 525px) {
  .mdl-layout__header-row {
    padding: 0 !important;
  }
  .mdl-layout__header-row .mdl-navigation__link {
    padding: 0 5px !important;
  }
  #navigationText {
    font-size: 6.8vw !important;
  }
}

#navigationText {
  background-color: transparent;
  color: #c432a1;
  font-size: x-large;
  text-decoration: none;
}

nav {
  background-color: rgba(0, 0, 0, 0);
}

.video {
  width: 100%;
  height: 100%;
  margin: auto;
  padding: 0;
  background-repeat: no-repeat;
  z-index: 1;
  display: block;
  position: relative;
  text-align: left;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
}

.sectionPage {
  font-family: "News Cycle", sans-serif;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
  margin: auto;
  padding-left: 6%;
  padding-right: 6%;
  padding-top: 4%;
  padding-bottom: 4%;
  display: block;
  position: relative;
  text-align: left;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
}

#myprojectdisplays {
  display: flex;
  align-items: center;
}

.bodyText {
  font-family: "News Cycle", sans-serif;
  font-weight: 300;
  font-size: 50px;
}

#CollapseSection {
  width: 70%;
}

html {
  scroll-behavior: smooth;
}

/* width */
::-webkit-scrollbar {
  width: 10px;
}

/* Track */
::-webkit-scrollbar-track {
  background: black;
}

/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(168, 213, 255);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(79, 76, 90);
}
