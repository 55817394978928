#aboutme {
  align-items: center;
  background: #1c92d2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #131a43,
    #321a5c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #131a43,
    #321a5c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#about-me-title {
  font-family: "Heebo", sans-serif;
  color: #c432a1;
  font-weight: 300;
  font-size: 70px;
}

.collapsible-header {
  color: #c432a1;
  background-color: transparent;
  font-size: larger;
}

.collapsible-body {
  font-size: larger;
}

#aboutMeSection {
  align-items: flex-start;
  display: flex;
  justify-content: center;
  color: rgb(168, 213, 255);

  /* padding: 0 16px 16px; */
}

#AboutPicture {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 25vw;
  /* width: 25%; */
  padding-right: 20px;
}

#aboutMeWritten {
  font-size: large;
  /* font-size: 1.8vw; */
  display: flex;
  text-align: left;
  align-items: center;
}

#connect {
  font-size: 25px;
  color: #2382ff;
  white-space: nowrap;
  float: right;
  padding-top: 5vh;
}

#AboutMe {
  font-family: "News Cycle", sans-serif;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: fit-content;
  min-height: 100vh;
  margin: 0;
  z-index: 1;
  display: block;
  position: relative;
  text-align: left;
  min-width: 100%;
  overflow: hidden;
  align-items: center;
  background: #1c92d2; /* fallback for old browsers */
  background: -webkit-linear-gradient(
    to right,
    #131a43,
    #321a5c
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #131a43,
    #321a5c
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

@media screen and (max-width: 646px) {
  #about-me-title {
    text-align: right;
    font-size: 14vw;
    padding-top: 8.5%;
    line-height: 60%;
  }

  #connect {
    font-size: 5vw;
    float: unset;
  }

  #aboutMeSection {
    padding-left: 4%;
    padding-right: 4%;
  }

  #AboutPicture {
    display: none;
  }

  #aboutMeWritten {
    font-size: medium;
  }
}
