#Projects {
  background: -webkit-linear-gradient(
    to right,
    #c432a1,
    #0e0849
  ); /* Chrome 10-25, Safari 5.1-6 */
  background: linear-gradient(
    to right,
    #c432a1,
    #0e0849
  ); /* W3C, IE 10+/ Edge, Firefox 16+, Chrome 26+, Opera 12+, Safari 7+ */
}

#projects-title {
  font-family: "Heebo", sans-serif;
  background: rgb(255, 255, 255);
  background: linear-gradient(
    39deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(55, 37, 88, 1) 60%
  );
  background-clip: text;
  -webkit-text-fill-color: transparent;
  font-weight: 300;
  font-size: 70px;
}

@media screen and (max-width: 515px) {
  #projects-title {
    text-align: center;
    font-size: 14vw;
    padding-top: 8.5%;
    background: linear-gradient(
      39deg,
      rgba(255, 255, 255, 1) 0%,
      rgba(55, 37, 88, 1) 100%
    );
    background-clip: text;
  }
}
