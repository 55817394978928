#home-page-background {
  font-family: "News Cycle", sans-serif;
  background-size: cover;
  background-position: center center;
  width: 100%;
  height: 100vh;
  margin: auto;
  z-index: 1;
  display: block;
  position: relative;
  text-align: left;
  min-height: 100%;
  min-width: 100%;
  overflow: hidden;
}

#name-title {
  color: #00e0f0;
}

#loop-text {
  font-family: "News Cycle", sans-serif;
  color: #c432a1;
}

@media screen and (max-width: 925px) {
  #name-title {
    font-size: 20vw;
    top: 50%;
    left: 11%;
  }

  #loop-text {
    font-size: 8vw !important;
  }
}

@media screen and (min-width: 925px) {
  #name-title {
    font-size: 90px;
    top: 50%;
    left: 57%;
  }

  #loop-text {
    font-size: 40px !important;
  }
}
